import React, { useState } from "react";
const Result = () => {
  return (
    <p className="success-message">
      Your Message has been successfully sent. VisualDroids will contact you
      soon.
    </p>
  );
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);

  async function sendContact(event) {
    event.preventDefault();
    const email = document.getElementById("clientEmail").value;
    const clientMessage = document.getElementById("clientMessage").value;
    const webhookBody = {
      embeds: [
        {
          title: "Contact Form Submitted",
          fields: [
            { name: "email", value: email },
            { name: "Message", value: clientMessage },
          ],
        },
      ],
    };
    const webhookUrl =
      "https://discord.com/api/webhooks/1082667774704361583/GCe0-SFFwyxayhTKdvYtxh-m6PiB6Vw3UF8UPE0ltV9v-cHr2Q9VskWV-rZPj0KCjvlK";
    const response = await fetch(webhookUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(webhookBody),
    });
    if (response.ok) {
      showresult(true);
      var toreset = document.getElementsByName("Visualdroidsform")[0]
      toreset.reset();
    } else {
      setTimeout(() => {
        showresult(false);
      }, 5000);
    }
  }

  return (
    <form action="" onSubmit={sendContact} name="Visualdroidsform">
      

      <div className="rn-form-group">
        <input
          type="email"
          name="email"
          placeholder="Your Email"
          id="clientEmail"
          required
        />
      </div>

      
      <div className="rn-form-group">
        <textarea
          name="message"
          placeholder="Your Message"
          id="clientMessage"
          required
        ></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="header-area header-wrapper a rn-btn"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Submit Now
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
