import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";
import { AiOutlineGithub } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";

class Accordion01 extends Component {
  render() {
    return (
      <Accordion className="accodion-style--1" preExpanded={"1"}>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>How to install the tools</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className="listado">
              <li>
                Download the latest release of the tool you want to install.
              </li>
              <ul>
                <li className="resaltado">For Windows:</li>
                <ul>
                  <li>Run Windows Installer.exe</li>
                  <li>If a "Windows Protected your PC" dialog shows up:</li>
                  <ul>
                    <li>Click More Info then Run Anyway</li>
                  </ul>
                </ul>
                <li className="resaltado">For macOS:</li>
                <ul>
                  <li>
                    While holding control in your keyboard, single click MacOS
                    Installer.app and click Open
                  </li>
                  <li>
                    When a macOS cannot verify the developer... dialog shows up,
                    click Open. If you can't find the Open button, try the last
                    step again.
                  </li>
                </ul>
              </ul>

              <li>Choose your Toonboom Harmony Version</li>
              <li>Click on Install</li>
              <li>Open Toonboom Harmony</li>
              <li>
                Right click on any toolbar and select the name of the tool you
                want to add, for example: <span>Visual Droids EZ Render</span>
              </li>
              <img
                src="/assets/images/faqs/addtool.webp"
                alt="Add Tool Harmony "
              />
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>How to install h264 codec</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul className="listado">
              <li className="resaltado">For macOS</li>
              <ul>
                <li>
                  Open the spotlight for search:
                  <img
                    className="configlogo"
                    src="/assets/images/faqs/ConfigWizard.png"
                    alt="ConfigWizardIcon"
                  />
                  Configuration Wizard
                </li>
                <li>Select and excute the Configuration Wizard. </li>
                <li>
                  In the list of tasks select :
                  <span> Install/Uninstall Cisco OpenH264 Library.</span>
                </li>
                <li>
                  Also you can find the
                  <img
                    className="configlogo"
                    src="/assets/images/faqs/ConfigWizard.png"
                    alt="ConfigWizardIcon"
                  />
                  Configuration Wizard: Opening ToonBoom Harmony, select Harmony
                  Premium and select Harmony Applications folder.
                </li>
              </ul>
              <img
                src="/assets/images/faqs/codech264.webp"
                alt="CodecH264MacOs"
              />
              <li className="resaltado">For Windows:</li>
              <ul>
                <li>
                  Search{" "}
                  <img
                    className="configlogo"
                    src="/assets/images/faqs/ConfigWizard.png"
                    alt="ConfigWizardIcon"
                  />
                  Configuration Wizard in all your programs and execute.
                </li>
                <li>
                  Then in the list of tasks select :{" "}
                  <span>Cisco OpenH264 Library.</span>
                </li>
                <li>
                  Then select <span>Yes/Install</span> and Finish the process
                </li>
              </ul>
            </ul>
          </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>
              Can I download older versions ?
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="normaldescription">
              Yes, it is possible to download previous versions. You have our
              github account and there you can check for all the releases for
              the especific tool that you need, where all the versions are
              listed. However, the latest version is always considered the most
              stable.
              <br />
              Select the tool, and get the list of all the releases:
            </p>
            <div className="icon-container">
              <a
                className="boton"
                href="https://github.com/VisualDroids/EZ-Render-for-Toonboom-Harmony/releases"
              >
                EZ Render
              </a>
              <a
                className="boton"
                href="https://github.com/VisualDroids/EZ-Backdrop-for-Toonboom-Harmony-Updates/releases"
              >
                EZ Backdrop
              </a>
              <a
                className="boton"
                href="https://github.com/VisualDroids/EZ-Backup-for-Toonboom-Harmony/releases"
              >
                EZ Backup
              </a>
            </div>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>How can I report a bug ?</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <p className="normaldescription">
              If you are experiencing issues while using our tools and require
              personalized assistance, contact us through our Discord channel,
              which you can access by clicking on the following icon. <br />
              <a
                href="https://discord.gg/ZVnE6yK86E"
                className="icon-container"
              >
                <FaDiscord size={50} color="#ff12f3" />
              </a>
              There, you can send us a detailed report of the problem or bug,
              and even schedule a meeting with us to resolve it.
              <br /> Additionally, you can also reach out to us via email at:{" "}
              <a href="mailto:info@visualdroids.com">info@visualdroids.com</a>
            </p>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}

export default Accordion01;
