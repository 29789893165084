import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-12 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
                <p className="description">
                  Welcome to our contact section! Want to talk to us? We have
                  excited people eager to listen! No matter if you have
                  questions, comments or just want to say hi, we're here to
                  help.
                </p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            {/* <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img src="/assets/images/about/VD-1.jpg" alt="trydo" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
