import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF , FaLinkedinIn , FaTwitter } from "react-icons/fa";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class About extends Component{
    render(){
        let title = 'About us',
        description = 'Visual Droids strives to empower the creative minds behind the art of animation, inspired by the story of three friends from diverse fields coming together to pursue a common vision. As they worked together they realized that the tools and technology available to animators were not being brought to their full potential. They decided to combine their expertise and create a company that would provide artists with the tools and technology they need to breathe life into their imaginations. With a comprehensive suite of services, we endeavor to be the trusted partner in their journey of artistic expression, employing innovative solutions to streamline their workflows and enhance their efficiency.';
        return(
            <React.Fragment>
                <PageHelmet pageTitle='About us' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'About'}   />
                {/* End Breadcrump Area */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="rn-about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/Visualdroids-About.jpg" alt="About Images"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                 

                {/* Start Team Area  */}
                <div className="rn-team-area bg_color--1 ptb--120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25">
                                    <h2 className="title">Skilled Team</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            
                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/Miguel1.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Miguel Brito</h4>
                                        <p className="designation">Full Stack Cholo</p>
                                    </div>
                                    <ul className="social-icon" >
                                        {/*<li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>*/}
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        {/*<li><a href="https://twitter.com/"><FaTwitter /></a></li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/Manuel2.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Manuel Aguilar</h4>
                                        <p className="designation">Tu recuerdo</p>
                                    </div>
                                    <ul className="social-icon" >
                                        {/*<li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>*/}
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        {/*<li><a href="https://twitter.com/"><FaTwitter /></a></li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                            {/* Start Single Team  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="team">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/team/Gustavo2.jpg" alt="Blog Images"/>
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Gustavo García</h4>
                                        <p className="designation">VFX Animator and Full Stack Chikiflow </p>
                                    </div>
                                    <ul className="social-icon" >
                                        {/*<li><a href="https://www.facebook.com/"><FaFacebookF /></a></li>*/}
                                        <li><a href="http://linkedin.com/"><FaLinkedinIn /></a></li>
                                        {/*<li><a href="https://twitter.com/"><FaTwitter /></a></li>*/}
                                    </ul>
                                </div>
                            </div>
                            {/* End Single Team  */}

                        </div>
                    </div>
                </div>
                {/* End Team Area  */}

                

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default About