import React, { Component } from "react";
import Accordion01 from "./Accordion";
import Header from "../component/header/Header";

class FAQ extends Component {
  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        {/* Start About Area  */}
        <div className="rn-about-area ptb--170">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-6">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/visualdroids.png"
                    alt="About Images"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">Welcome to our FAQ section!</h2>
                    <p>
                      
                      We understand that you may have questions, and we're here
                      to provide you with the answers you need. Below, you'll
                      find some common inquiries along with clear and concise
                      responses. If you don't see your question listed, please
                    reach out your question to our
                      support team for further assistance.
                    </p>
                  </div>
                  <div className="accordion-wrapper mt--30">
                    <Accordion01 />
                  </div>
                  <div className="about-button mt--50">
                    <a className="rn-button-style--2 btn-solid" href="/contact">
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}
        {/* End Breadcrump Area */}
      </React.Fragment>
    );
  }
}

export default FAQ;
