import React, { Component } from "react";

class About extends Component {
  render() {
    let title = "About us",
      description =
        "Visual Droids strives to empower the creative minds behind the art of animation, inspired by the story of three friends from diverse fields coming together to pursue a common vision. As they worked together they realized that the tools and technology available to animators were not being brought to their full potential. They decided to combine their expertise and create a company that would provide artists with the tools and technology they need to breathe life into their imaginations. With a comprehensive suite of services, we endeavor to be the trusted partner in their journey of artistic expression, employing innovative solutions to streamline their workflows and enhance their efficiency.";
    return (
      <React.Fragment>
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 col-md-12">
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src="/assets/images/about/Visualdroids-About.jpg"
                    alt="About Images"
                  />
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">{title}</h2>
                    <p className="description">{description}</p>
                  </div>
                  <div className="row mt--30 mt_sm--10">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default About;
