import React, { Component } from "react";
import { FiTrendingUp, FiSettings, FiUsers } from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiTrendingUp />,
    title: "Increase your productivity",
    description:
      "Optimize time and resources by using scripts that create base templates for you.",
  },
  {
    icon: <FiSettings />,
    title: "We adapt to your workflow",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiUsers />,
    title: "We work with you",
    description:
      "Tell us any process you want to automate and together we create a tool to streamline your work.",
  },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services",
      description =
        "We offer high-quality, customized solutions tailored to individual workflows, providing technical support and service to our clients. Our mission is to equip artists in animation with solutions specifically designed for their needs, with the goal of reducing production costs while enhancing quality.";
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
              <h2 className="title">{title}</h2>
              <p>{description}</p>
              <div className="service-btn">
                <a className="btn-transparent rn-btn-dark" href="/contact">
                  <span className="text">Request Custom Service</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12 mt_md--50">
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
