import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick2 } from "../../../page-demo/script";
import { Link } from "react-router-dom";

const PortfolioList = [
  {
    image: "image-1",
    category: "Tool",
    title: "EZ Render for Toonboom Harmony",
  },
];

class Portfolio extends Component {
  render() {
    let title = "Our Works",
      description =
        "Here you have a variety of tools designed to improve the productivity and efficiency of our clients.";
    return (
      <React.Fragment>
        <div className="portfolio-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="section-title">
                  <h2>{title}</h2>
                  <p>{description}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row justify-content-md-center">
              <div className="col-sm">
                <figure class="snip1527">
                  <div class="image">
                    <img
                      src="assets/images/portfolio/thumbnail.jpg"
                      alt="pr-sample23"
                    />
                  </div>
                  <figcaption>
                    <h3>EZ Render for Toonboom Harmony</h3>
                  </figcaption>
                  <a href="https://stuff.visualdroids.com/l/ezrender"></a>
                </figure>
              </div>
              <div className="col-sm">
                <figure class="snip1527">
                  <div class="image">
                    <img
                      src="assets/images/portfolio/EZBackdrop.webp"
                      alt="pr-sample23"
                    />
                  </div>
                  <figcaption>
                    <h3>EZ Backdrop for Toonboom Harmony</h3>
                  </figcaption>
                  <a href="https://stuff.visualdroids.com/l/ezbackdrop"></a>
                </figure>
              </div>

              <div className="col-sm">
                <figure class="snip1527">
                  <div class="image">
                    <img
                      src="assets/images/portfolio/EZBackup.png"
                      alt="pr-sample23"
                    />
                  </div>
                  <figcaption>
                    <h3>EZ Backup for Toonboom Harmony</h3>
                  </figcaption>
                  <a href="https://stuff.visualdroids.com/l/ezbackup"></a>
                </figure>
              </div>
            </div>
            <div className="row justify-content-md-center">
              <div className="col-sm">
                <figure class="snip1527">
                  <div class="image">
                    <img
                      src="assets/images/portfolio/SublayerPreparator.webp"
                      alt="pr-sample23"
                    />
                  </div>
                  <figcaption>
                    <h3>Sublayer Preparator for Toonboom Harmony</h3>
                  </figcaption>
                  <a href="https://stuff.visualdroids.com/l/sublayerpreparator"></a>
                </figure>
              </div>
              <div className="col-sm">
                
              </div>
              <div className="col-sm">
                
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Portfolio;
