import React, { Component } from "react";
import ServiceOne from "../../elements/service/ServiceOne";
import Typed from 'react-typed';

class SliderOne extends Component {
  render() {
    return (
        <div className="slider-activation">
            {/* Start Single Slide */}
            <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_color--8" data-black-overlay="1">
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner">
                                <h1 className="title theme-gradient">
                                    visual <br /> droids 
                                    <Typed 
                                    className="title theme-gradient"
                                    strings={['.','..','...']}
                                    typeSpeed = {120}
                                    backSpeed = {140}
                                    loop
                                    />
                                
                                
                                
                                </h1>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div> 
            {/* End Single Slide */}
        </div>
    );
  }
}
export default SliderOne;